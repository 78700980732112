import React, { CSSProperties, FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

import Typography, { TypographyProps } from 'components/atoms/Typography';

import { StyledLinkItem, StyledLinksFooter } from './LinksFooter.styles';

import { LinksFooterProps } from './models.d';

const LinksFooter: FC<LinksFooterProps> = ({ links }) => {
  const {
    allContentfulCaseStudiesListingPage: { caseStudiesPages },
  } = useStaticQuery(graphql`
    {
      allContentfulCaseStudiesListingPage {
        caseStudiesPages: nodes {
          pageName
          slug
          caseStudyList {
            tag
          }
        }
      }
    }
  `);

  if (!links?.length) return null;

  const pageTags = caseStudiesPages.reduce(
    (a, b) => ({
      [b.pageName.toLowerCase()]: [...new Set(b.caseStudyList.map(({ tag }) => kebabCase(tag)))],
      ...a,
    }),
    {}
  );

  const defaultLinkTypography: TypographyProps = {
    fontWeight: 500,
    padding: { bottom: 8, top: 8, left: 4, right: 4 },
    lineHeight: 1.5,
    asEl: 'div',
  };

  const subItems = (subLinks) =>
    subLinks?.map((link, idx) => (
      <li key={`footer-sub-nav-item-${link.id}-${idx}`}>
        <StyledLinkItem
          typography={{
            ...defaultLinkTypography,
            color: 'gray-400',
          }}
          style={{ '--navlink-mb': '2px' } as CSSProperties}
          to={link}
          query={link.query}
        >
          {link.label}
        </StyledLinkItem>
      </li>
    ));

  return (
    <StyledLinksFooter data-testid="links-footer">
      {links.map((linksItems, index) =>
        linksItems.pageLink ? (
          <li key={`footer-nav-item-${linksItems.id}-${index}`}>
            {linksItems.subNavItems ? (
              <ul>
                <li key={`footer-sub-nav-main-item-${linksItems.id}-${index}`}>
                  <StyledLinkItem
                    typography={{
                      ...defaultLinkTypography,
                      color: 'white',
                      size: 14,
                    }}
                    style={{ '--navlink-mb': '4px' } as CSSProperties}
                    to={linksItems}
                  >
                    {linksItems.label}
                  </StyledLinkItem>
                </li>
                {subItems(
                  Object.keys(pageTags).includes(linksItems.label.toLowerCase())
                    ? linksItems.subNavItems.filter(({ query }) =>
                        pageTags[linksItems.label.toLowerCase()].includes(
                          query.replace('?tag=', '')
                        )
                      )
                    : linksItems.subNavItems
                )}
              </ul>
            ) : (
              <StyledLinkItem
                typography={{
                  ...defaultLinkTypography,
                  color: 'white',
                  size: 14,
                }}
                style={{ '--navlink-mb': '4px' } as CSSProperties}
                to={linksItems}
              >
                {linksItems.label}
              </StyledLinkItem>
            )}
          </li>
        ) : (
          <li>
            <Typography
              key={`footer-nav-item-${linksItems.id}-${index}`}
              color="white"
              asEl="p"
              size={14}
              fontWeight={500}
              padding={{ bottom: 16 }}
            >
              {linksItems.label}
            </Typography>
          </li>
        )
      )}
    </StyledLinksFooter>
  );
};

export default LinksFooter;
