import styled from 'styled-components';
import { borderBottom, breakpoint } from 'styles';

import Link from 'components/atoms/Link';

export const StyledLinksFooter = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-xxxl);
  padding: var(--gap-lg) 0;

  ${breakpoint.md} {
    padding: var(--gap-lg) 0 var(--gap-lg) var(--gap-lg);
  }

  ${breakpoint.lg} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${borderBottom}
`;

export const StyledLinkItem = styled(Link)`
  display: inline-block;
  height: 100%;
  margin-bottom: var(--navlink-mb);
  margin-left: -4px;
  border: 2px solid var(--c-transparent);
  border-radius: var(--border-radius-md);
`;
